import {
  InstrumentAction,
  InstrumentEvent,
  InstrumentStatus,
  InstrumentTagType,
  InstrumentUploaderType,
} from '@finverity/graphql';
import { FinButtonAppearance } from '@finverity/ui-kit';

import { InstrumentApprovalConfigs, InstrumentApprovalRejectedSubscriptionEvents } from '../../../types';
import { InstrumentRejectedDataGridColId } from '../../instrument-rejected-container/constants';

export enum SellerFinanceRequestRejectedFilterIdentifier {
  SellerFinanceRequestRejected = 'SELLER_FINANCE_REQUEST_REJECTED',
  NotFundable = 'NOT_FUNDABLE',
}

export const SELLER_INSTRUMENT_FINANCE_REQUEST_REJECTED_CONFIGS: InstrumentApprovalConfigs = {
  companyType: InstrumentUploaderType.Seller,
  pageTitle: 'Seller Finance Request (Rejected)',
  actionsDefs: [
    {
      disabled: true,
      buttonText: 'Undo Rejection',
      buttonSvgIconName: 'fvIconInstrumentApprovalUndoRejection',
      buttonAppearance: FinButtonAppearance.Primary,
      actionType: InstrumentAction.SellerFinanceUndoRejection,
    },
  ],
  filtersDefs: [
    {
      identifier: SellerFinanceRequestRejectedFilterIdentifier.SellerFinanceRequestRejected,
      label: 'Seller Finance Request(Rejected)',
      params: {
        eventsType: [InstrumentEvent.SellerFinanceCheckerRejected, InstrumentEvent.SellerFinanceMakerRejected],
        statuses: [InstrumentStatus.SellerFinanceRejected],
        tags: {
          notIncludes: [
            InstrumentTagType.Deleted,
            InstrumentTagType.NotFundable,
            InstrumentTagType.NoEligibleFundingWindow,
          ],
        },
      },
      count: 0,
      hiddenColumns: [],
    },
    {
      identifier: SellerFinanceRequestRejectedFilterIdentifier.NotFundable,
      label: 'Not Fundable',
      params: {
        eventsType: [InstrumentEvent.SellerFinanceCheckerRejected, InstrumentEvent.SellerFinanceMakerRejected],
        statuses: [InstrumentStatus.SellerFinanceRejected],
        tags: {
          notIncludes: [InstrumentTagType.Deleted],
          includes: [InstrumentTagType.NotFundable, InstrumentTagType.NoEligibleFundingWindow],
        },
      },
      count: 0,
      hiddenColumns: [
        InstrumentRejectedDataGridColId.DisbursementDate,
        InstrumentRejectedDataGridColId.Tenor,
        InstrumentRejectedDataGridColId.RepaymentDate,
        InstrumentRejectedDataGridColId.AdvanceRate,
        InstrumentRejectedDataGridColId.PrincipalAmount,
        InstrumentRejectedDataGridColId.DisbursementAmount,
        InstrumentRejectedDataGridColId.RepaymentAmount,
        InstrumentRejectedDataGridColId.NetUnfinancedBalance,
        InstrumentRejectedDataGridColId.SellerFeesInAdvance,
        InstrumentRejectedDataGridColId.PayerFeesInAdvance,
        InstrumentRejectedDataGridColId.SellerFeesInArrears,
        InstrumentRejectedDataGridColId.PayerFeesInArrears,
        InstrumentRejectedDataGridColId.TotalSellerFees,
        InstrumentRejectedDataGridColId.TotalPayerFees,
        InstrumentRejectedDataGridColId.TotalFees,
      ],
    },
  ],
  subscriptionEvents: <InstrumentApprovalRejectedSubscriptionEvents>{
    undoRejected: [InstrumentEvent.SellerFinanceMakerPending, InstrumentEvent.FunderApprovalMakerPending],
    updated: [InstrumentEvent.InstrumentEdited],
    removed: [InstrumentEvent.InstrumentDeleted],
  },
  viewUrl: 'seller-finance-request-rejected/view',
  editUrl: 'seller-finance-request-rejected/edit',
};
